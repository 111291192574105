import { useContext } from "react";
import { useUser } from "../../../Components/User/User.hook";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";

export const useUserBox = () => {
    const { user, isLoggedIn } = useUser();
	const { main_domain, country_code } = useContext(ConfigStateContext);

	const DATA_PUBILCATE_PROPERTY_DROPDOWN = [
		{
			title: "Inmobiliaria",
			url: "/soyinmobiliaria",
		},
		{
			title: "Dueño Vende",
			url: "/publicar",
		},
		{
			title: "Dueño Alquila Anual",
			url: "/publicar",
		},
		{
			title: "Dueño Alquila Temporario",
			url: "/publicar-alquiler-temporario",
		},
	];

	const DATA_USER_DROPDOWN = {
		IS_LOGGED_INDIVIDUAL: [
			{
				title: "Perfil",
				url: "/dashboard",
			},
			{
				title: "Favoritos",
				url: "/favoritos",
			},
		],

		NOT_IS_LOGGED_INDIVIDUAL: [
			{
				title: "Panel Inmobiliario",
				url: "/sitio/index.php?mid=inmobiliarias&func=panel",
			},
			{
				title: "Alquileres Temporales",
				url: "/sitio/index.php?mid=inmobiliarias&func=panelPropiedades",
			},
			{
				title: "Administrador",
				url: "/sitio/index.php?mid=superadmin&func=vendedores",
			},
			{
				title: "Panel de Pagos",
				url: "/sitio/index.php?mid=pagos",
			},
			{
				title: "Tarjetas de Crédito",
				url: "/sitio/index.php?mid=pagos&func=view_administrar_tarjeta",
			},
		],
	};

	const loggedIndividual = DATA_USER_DROPDOWN.IS_LOGGED_INDIVIDUAL;

	const data = user?.data?.me?.individual
		? loggedIndividual
		: DATA_USER_DROPDOWN.NOT_IS_LOGGED_INDIVIDUAL;

	const dataPP = user?.data?.me?.individual
		? DATA_PUBILCATE_PROPERTY_DROPDOWN.slice(1, DATA_PUBILCATE_PROPERTY_DROPDOWN.length)
		: DATA_PUBILCATE_PROPERTY_DROPDOWN;

    return {
        data,
		dataPP,
        main_domain,
        user,
        country_code,
        isLoggedIn
    }
}