import moment from "moment";
import { useState } from "react";
import { LoadingIcon } from "shared-components/Components/CustomIcons/LoadingIcon/LoadingIcon";
import { useUserBox } from "shared-components/ViewFragments/Header/UserBox/hook";

const ID_FORM_MAPFRE = 455;
const ID_FORM_PORTO = 21;
const ID_FORM_SURA = 1149;

export const FormGarantiasAlquiler = ({ handleShowModal, handleShowAlert, setAlertData }: { handleShowModal: Function, handleShowAlert: Function, setAlertData: Function }) => {
    const { user, country_code } = useUserBox();
    const urlCompleta = window.location.href;
    const url = new URL(urlCompleta);
    const searchParams = url.searchParams;
    const [isLoading, setIsLoading] = useState(false);

    const [credentials, setCredentials] = useState({
        name: "",
        email: "",
        phone: "",
        ci: "",
        amount: "",
        supplier: "Mapfre",
    });

    const suppliers = ["Mapfre", "Porto Seguro", "Sura Seguros"];

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const credentialsFormat: any = { ...credentials };
        credentialsFormat.form_type = 'warranty';
        credentialsFormat['id_inmo'] = user?.data.me.id;
        credentialsFormat['name_inmo'] = credentials.name;
        credentialsFormat['email_inmo'] = credentials.email;
        credentialsFormat['phone_inmo'] = credentials.phone;
        credentialsFormat['countryCode'] = country_code;
        credentialsFormat['platform'] = "Infocasas";

        let idForm = 0

        switch (credentials.supplier) {
            case 'Mapfre':
                idForm = ID_FORM_MAPFRE
                break;
            case 'Porto Seguro':
                idForm = ID_FORM_PORTO
                break;
            case 'Sura Seguros':
                idForm = ID_FORM_SURA
                break;
            default:
                break;
        }

        const formData = {
            IDuser: parseInt(user?.data.me.id),
            nombre: credentials.name,
            email: credentials.email,
            telefono: credentials.phone,
            ci: credentials.ci,
            fecha: moment().format("YYYY-MM-DD HH:mm"),
            no_mailchimp_suscribe: true,
            IDform: idForm,
            IDPais: 1
        }

        let utm_content = `Datos de la inmobiliaria: ID '${user?.data.me.id}', Nombre '${user?.data.me.name}', Email '${user?.data.me.email}'`.slice(0, 80)
        const utms = {
            utm_content: utm_content,
            utm_source: "panel_inmobiliaria",
            utm_medium: searchParams.get("utm_medium") != null ? Array.isArray(searchParams.get("utm_medium")) ? searchParams.get("utm_medium")[0] : searchParams.get("utm_medium").toString() : undefined,
            utm_campaign: searchParams.get("utm_campaign") != null ? Array.isArray(searchParams.get("utm_campaign")) ? searchParams.get("utm_campaign")[0] : searchParams.get("utm_campaign").toString() : undefined,
        }

        fetch(
            "https://prod.infocasas.com.uy/microservices/iris/?/send_email=",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentialsFormat),
            },
        ).then((res) => res.json())
            .catch((err) => console.log(err))

        const sendData = { ...formData, ...utms }

        fetch(
            "https://prod.infocasas.com.uy/?mid=formulario&func=ajax_save&json=true",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sendData),
            },
        ).then((res) => res.json())
            .then((json) => {
                let data = {
                    status: '',
                    message: '',
                }

                if (json) {
                    switch (json.error) {
                        case '00':
                            data.status = 'success'
                            data.message = 'Consulta enviada con éxito.'
                            break
                        case '02':
                            data.status = 'warning'
                            data.message = 'Su consulta ya ha sido enviada antes.'
                            break
                        default:
                            data.status = 'error'
                            data.message = 'Hubo un error. Vuelva a intentarlo.'
                            break
                    }
                } else {
                    data.status = 'error'
                    data.message = 'Hubo un error. Vuelva a intentarlo.'
                    new Error('Error Sending Form to IC1')
                }

                setAlertData(data);
                setIsLoading(false);
                handleShowModal(false);
                handleShowAlert(true);
            })
            .catch((err) => console.log(err))
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
    };

    return (
        <div className="container-garantia mt-5">
            <form className="custom-form" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                        Nombre
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={credentials.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={credentials.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                        Teléfono
                    </label>
                    <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={credentials.phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="ci" className="form-label">
                        C.I.
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="ci"
                        name="ci"
                        value={credentials.ci}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="amount" className="form-label">
                        Monto
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="amount"
                        name="amount"
                        value={credentials.amount}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="supplier" className="form-label">
                        ¿A quién solicitas esta garantía?
                    </label>
                    <select
                        className="form-select"
                        id="supplier"
                        name="supplier"
                        value={credentials.supplier}
                        onChange={handleChange}
                        required
                    >
                        {suppliers.map((supplier) => (
                            <option key={supplier} value={supplier}>
                                {supplier}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">
                    Enviar
                    {isLoading && <LoadingIcon classes="loading-icon" />}
                </button>
            </form>
        </div>
    )
}