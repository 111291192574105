import { useRouter } from "next/router";
import { BannerFlotante } from "./BannerFlotante/web";
import { BannerHeader } from "./BannerHeader/web";
import { useBannersHeader } from "./hook";
import { useFilters } from "shared-components/Components/Filters/Filters.hook";
import { useUser } from "shared-components/Components/User/User.hook";
import { useContext, useEffect, useState } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import getConfig from "next/config";
import { ipIsFromCountry } from "../BannerArgentoInvest/web";

const { irisEndpoint } = getConfig().publicRuntimeConfig;

export const BannersHeader = () => {
	const { country_code } = useContext(ConfigStateContext);
	const { pathname, asPath } = useRouter();
	const { filters } = useFilters();
	const { type, banner } = useBannersHeader({ where: getWhereFromPathname(pathname) });
	const { user } = useUser();
	const [irisEvent, setIrisEvent] = useState({} as any);
	const [randomPosition, setRandomPosition] = useState(Math.round(Math.random()));
	const [bannerToShow, setBannerToShow] = useState("");
	const [isFromAr, setIsFromAr] = useState<boolean>(false);

	useEffect(() => {
		if (user && user.data && user.data.me && !user.data.me.individual) {
			getIrisEvents(country_code).then((res) => {
				if (res.success) setIrisEvent(res.data);
			});
		}
	}, [user.data]);

	useEffect(() => {
		if (!banner) setRandomPosition(0)

		if (irisEvent.event && randomPosition === 0 && irisEvent.event.link && irisEvent.event.link !== "") {
			setBannerToShow("iris");
		} else if (banner) {
			setBannerToShow("infocasas");
		};
	}, [irisEvent, banner]);

	useEffect(() => {
        //Temporary commented
        // ipIsFromCountry("AR")
        //     .then((res) => setIsFromAr(res))
    }, [])

	if (
		(((banner == null || type == null) && irisEvent.event == null) ||
		(filters && filters.page >= 2 && user.data.me) ||
		asPath.startsWith('/listado') ||
		pathname.startsWith('/propSingle'))
		//&& !isFromAr
	) return null;

	if (bannerToShow === "iris") {
		return <BannerFlotante data={irisEvent.event} />;
	} else if (bannerToShow === "infocasas") {
		if (type == "floting") return <BannerFlotante data={banner} />; // !isFromAr agregar para activar preheader AR
		else return <BannerHeader data={banner} />;//isFromAr={isFromAr} agregar para activar preheader AR
	}
	// else if (isFromAr) {
	// 	return <BannerHeader data={banner} isFromAr={isFromAr} />;
	// }

	return null;
};

// aux
const getWhereFromPathname = (pathname: string) => {
	if (pathname.indexOf("home") >= 0) return "portadainit";
	else if (pathname.indexOf("search") >= 0) return "propiedadeslistar";
	else if (pathname.indexOf("prop") >= 0 || pathname.indexOf("project") >= 0) return "propiedadesampliar";
	return "portadainit";
};

const getIrisEvents = async (country) => {
	const response = await fetch(`${irisEndpoint}/promos/current-promos`, {
		method: "POST",
		body: JSON.stringify({
			country_code: country,
			platform: "infocasas_panel"
		}),
		headers: {
			Accept: "*/*",
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.catch((error) => {
			throw new Error("Error: " + error.message);
		});

	return response;
}