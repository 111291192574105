import "./styles.less";

const appLinkIcons = [

	{
		id: "googleplay",
		display_name: "Google Play",
		name: "googleplay",
		src: "https://cdn2.infocasas.com.uy/web/64401d3288d5f_google.png",
		url: "https://play.google.com/store/apps/details?id=uy.com.infocasas.infoapp",
	},
	{
		id: "appstore",
		display_name: "App Store",
		name: "appstore",
		src: "https://cdn2.infocasas.com.uy/web/64401d1329fdd_apple.png",
		url: "https://itunes.apple.com/uy/app/infocasas/id1126880888?mt=8",
	}
];

export const AppLinks = () => {	
	return (
		<div className="app-links">			
			<div className="appLinksBtns">
				{appLinkIcons.map((e, i) => (
					<a href={e.url} target='_blank' key={i}>
						<img width="118" height="36" src={e.src} alt={'Botón que dirige a la tienda de aplicaciones de ' + e.name} />
					</a>
				))}
			</div>
		</div>
	);
};







