
import { YouTubeIcon } from "shared-components/Components/CustomIcons/YouTubeIcon/web";
import { removeUrlProtocol } from "../../../Utils/Functions";
import { useSocialNetworks } from "./SocialNetworks.hook";
import { FacebookIcon } from "shared-components/Components/CustomIcons/FacebookIcon/web";
import { LinkedInIcon } from "shared-components/Components/CustomIcons/LinkedInIcon/web";
import { InstagramIcon } from "shared-components/Components/CustomIcons/InstagramIcon/web";
import { TwitterIcon } from "shared-components/Components/CustomIcons/TwitterIcon/web";

const socialMediaIcons = Object.freeze({
	YouTube: <YouTubeIcon />,
	Facebook: <FacebookIcon />,
	LinkedIn: <LinkedInIcon />,
	Instagram: <InstagramIcon />,
	Twitter: <TwitterIcon />,
});

export const SocialNetworks = () => {
	const socialMediaLinks = useSocialNetworks();

	return (
		<div className="social-networks">
			<h5>{"Seguinos"}</h5>
			{socialMediaLinks?.map(({ url, name }, i) => url && (

				<a
					key={"link_box_item_" + i}
					href={removeUrlProtocol(url)}
					target="_blank" title={name}
					className="social-item"
					rel="nofollow"
				>
					{socialMediaIcons[name]}
					{name}
				</a>
			))}
		</div>
	);
};
