import React, { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

type HeaderMenuProps = {
	type: 'desktop' | 'mobile'
}

export const HeaderMenu = ({ type = 'desktop' }: HeaderMenuProps) => {
	const { header_links } = useContext(ConfigStateContext)

	const navClass = type == 'desktop' ? 'header_desktop_menu' : 'header_mobile_menu'
	const linkClass = type == 'desktop' ? 'header_btn' : 'header_btn_mobile'

	return (<>
		<nav className={navClass}>
			{header_links?.map(({ link, title }, i) =>
				<a className={linkClass} href={link} key={`headerMenu-${i}`}>
					{title}
				</a>
			)}
		</nav>
	</>);
};