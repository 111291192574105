import { useImage } from "./Image.hook"

type ImgOptimizedProps = {
    src: string
    alt?: string
    width?: number
    height?: number
    className?: string
    title?: string
}

export const ImagenOptimizada = ({ src, alt = "", width, height, className = "", title }: ImgOptimizedProps) => {
    const { getImagenOptimizada } = useImage()
    const defaultImg = 'https://tutaki.org.nz/wp-content/uploads/2019/04/no-image-1.png'

    const srcUrl = getImagenOptimizada({
        src: src ?? '',
        width: width ?? 1,
        height: height ?? 1
    })

    return <>
        {/*
        <Image 
            src={getImagenOptimizada({
                src: src,
                width: width ?? 1,
                height: height ?? 1
            })} 
            alt={alt} 
            width={width ?? 'auto'}  
            height={height ?? 'auto'}
            className={className}
            title={title}
        />
        */}
        <img src={srcUrl && srcUrl.length > 0 ? srcUrl : defaultImg}
            alt={alt}
            width={width ?? 'auto'}
            height={height ?? 'auto'}
            className={className}
            title={title}
        />
    </>
}