import { UserBox } from "shared-components/ViewFragments/Header/UserBox/web";
import { HeaderMenu } from "./HeaderMenu"
import { useWindowSize } from "shared-components/GlobalHooks/useWindowSize";

export const DrawerMobile = ({ visible, onClose }) => {
	const screens = useWindowSize()

	return (
		<>
			<div
				className={`mobileMenu-overlay ${visible ? "visible" : ""}`}
				onClick={onClose}></div>
			<div className={`mobileMenu-drawer ${visible ? "visible" : ""}`}>
				<div className="closeBtn" onClick={onClose}>
					<span>x</span>
				</div>
				 {/*
				 */}
				{!screens.xl && !screens.lg && <UserBox isMobile />}
				<HeaderMenu type="mobile" />
			</div>
		</>
	);
};