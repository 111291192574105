
export interface GetImagenOptimizadaProps {
	src: string;
	width?: number;
	height?: number;
	resize?: string;
}

export const useImage = () => {
	const getImagenOptimizada = ({ src, width, height, resize }: GetImagenOptimizadaProps) => {
		const d = Math.round(width) + "x" + Math.round(height);
		const resizes = resize ? resize : "outside";
		let res = String(src);

		const pattern = /cdn([1-9][0-9]*)/;
		res = res.replace(pattern, "cdn2");

		if (hasImgExtension(res)) {
			let segments = res.split("/");
			segments[segments.length - 1] = `th.${resizes}${d}.`.concat(
				segments[segments.length - 1]
			);
			res = segments.join("/");
		}

		return res;
	};

	return { getImagenOptimizada };
};

const hasImgExtension = (src: string): boolean => /\.(jpg|png|JPG|PNG|JPEG|jpeg)/g.test(src);
