import { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

export const useInstitucional = () => {
	const { country_code } = useContext(ConfigStateContext);
	const valid_country_code = (country_code !== "PE" && country_code !== "PY") ? "UY" : country_code;

	return {
		data: [
			{
				id: 1,
				name: "Términos de uso",
				url: `https://www.infocasas.com.${valid_country_code}/faq#boxtc`,
			},
			{
				id: 2,
				name: "Políticas de privacidad",
				url: `https://www.infocasas.com.${valid_country_code}/faq#boxpart`,
			},
			{
				id: 3,
				name: "Preguntas frecuentes",
				url: `https://www.infocasas.com.${valid_country_code}/faq`,
			}
		],
	};
};
