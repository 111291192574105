import { useContext } from "react";
import { removeUrlProtocol } from "../../../../Utils/Functions";
import { useInstitucional } from "./hook";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

export const Institucional = () => {
	const { country_code } = useContext(ConfigStateContext);
	const { data } = useInstitucional();

	return (
		<div className="institucional">
			{data?.map(({ url, name }, i) => (
				<a style={{ whiteSpace: 'nowrap', margin: "5px 0px", padding: "8px" }} key={"link_box_item_" + i} href={removeUrlProtocol(url)} title={name} target="_blank">
					{name}
				</a>
			))
			}

			{country_code === "ce3" &&
				<>
					<h4>Nuestras Empresas/Productos asociados:</h4>

					<a style={{ whiteSpace: 'nowrap', margin: "5px 0px", padding: "8px" }} href={removeUrlProtocol("https://www.grupocentrify.com")} title="Centrify" target="_blank">
						Centrify
					</a>
				</>
			}
		</div >
	);
};
