import { AppLinks } from "./AppLinks/web";
import { Flags } from "./Flags/web";
import './styles.less'


export const MoreAboutUs = () => {

	return (
		<div className="mau">
			<div className="mau__box">
				<h4 className="title"  > Descargá la app </h4>
				<AppLinks />
			</div>
			<div className="mau__box">
				<h4 className="title"  > Más InfoCasas </h4>
				<Flags />
			</div>
		</div>
	)
}
