import React, { useContext, useEffect } from "react";

import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import cookie from "js-cookie";
import { useUser } from "../User.hook";

export const Logout = ({className=''}) => {
	const { logout } = useUser();
	const { country_code } = useContext(ConfigStateContext);

	useEffect(() => {
		if (logout.response.data && !logout.response.loading) {
			cookie.remove("frontend_token");
			localStorage.removeItem("user_md5");
			localStorage.removeItem("one_tap_logged");
			if (country_code != "BR") {
				window.location.assign("/sitio/index.php?mid=login&func=logout");
				// router.push({ pathname: "/" });
			} else window.location.reload();
		}
	}, [logout.response]);

	return (
		<div className={className}
			onClick={() => {
				if (!logout.response.called) logout.send();
			}}>
			{"Cerrar Sesión"}
		</div>
	);
};
